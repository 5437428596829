import React from 'react'
import HeroBg from '../../../images/features-hero.jpg'
import Hero from './feature-hero.styles'

const FeatureHero = () => (
  <Hero background={HeroBg}>
    <Hero.contentContainer>
      <Hero.title>
        every tool you need <br /> to create your site
      </Hero.title>
      <Hero.subtitle>
        we've thought of everything, whoever you are, your site starts here
      </Hero.subtitle>
    </Hero.contentContainer>
  </Hero>
)

export default FeatureHero
