import styled from 'styled-components'
import theme, { media } from '../../../helpers/theme'
import RadioGroup from '@eig-builder/core-ui/RadioGroup'
import FormControlLabel from '@eig-builder/core-ui/FormControlLabel'

const PricingSection = styled.div`
  width: 100%;
  background-color: ${theme.colors.fadedGray};
  position: relative;
  padding: ${theme.space[12]}px ${theme.space[12]}px ${theme.space[5]}px;
`

PricingSection.contentContainer = styled.div`
  padding: ${theme.space[4]}px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
`

PricingSection.title = styled.span`
  font-size: ${theme.font['3xl']};
`

PricingSection.subtitle = styled.span`
  font-size: ${theme.font['xl']};
  color: ${theme.colors.font.gray};
  padding: ${theme.space[4]}px 0;
`

PricingSection.buttonContainer = styled.div``

PricingSection.radioGroup = styled(RadioGroup)`
  .MuiRadio-colorPrimary.Mui-checked {
    color: ${theme.colors.green};
  }

  .MuiTypography-body1 {
    font-size: ${theme.font['base']};
    font-family: 'Open Sans';
  }

  && {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: ${theme.space[6]}px 0;
  }
`

PricingSection.formControlLabel = styled(FormControlLabel)`
  && {
    color: ${(props) =>
      props.isSelected ? theme.colors.green : theme.colors.black};
    text-transform: uppercase;
    margin: 0 ${theme.space[4]}px;
  }
`

PricingSection.cardContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0.75rem;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: column;

  ${media('md')} {
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
  }
`

PricingSection.disclaimer = styled.div`
  max-width: 1200px;
  align-self: center;
  font-style: italic;
  font-size: ${theme.font['sm']};
  color: ${theme.colors.font.black};
  padding-top: ${theme.space[4]}px;

  ${media('md')} {
    padding: ${theme.space[12]}px ${theme.space[16]}px ${theme.space[5]}px
      ${theme.space[10]}px;
  }
`

export default PricingSection
