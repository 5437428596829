import IMAGE_1 from '../../../images/feature-descriptions-1.png'
import IMAGE_2 from '../../../images/feature-descriptions-2.png'
import IMAGE_3 from '../../../images/feature-descriptions-3.png'
import IMAGE_4 from '../../../images/feature-descriptions-4.png'

export const DESCRIPTION_CONTENTS = [
  {
    id: 1,
    title: "100's of Designs to Personalize Your Site",
    subtitle:
      "From small business to portfolios, bands to organizations, we'll have the perfect template for you. All of our templates are fully optimized for mobile and tablet devices.",
    image: IMAGE_1,
  },
  {
    id: 2,
    title: 'SEO Tools and Site Stats',
    subtitle:
      "We know how important search engine visibility is for your website. It's simple and quick to add keywords to index your site, then track their success along with visitors and page views.",
    image: IMAGE_2,
  },
  {
    id: 3,
    title: 'Web Hosting and Domain Included',
    subtitle:
      'We provide you with stable and reliable web hosting as standard, one less thing to worry about. You can also easily connect existing domains to your site or register a brand new one.',
    image: IMAGE_3,
  },
  {
    id: 4,
    title: 'Build Your Dream Site',
    subtitle:
      'Our drag and drop builder means your dream website is minutes away and can include any features you want, from an online store to an up-to-the-minute blog.',
    image: IMAGE_4,
  },
]
