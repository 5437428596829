import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'

const FeatureDescriptions = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.space[12]}px auto;
  padding: ${theme.space[5]}px;

  margin: 0 auto;

  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${theme.space[8]}px auto;
  width: 100%;

  ${media(768)} {
    flex-direction: ${(props) => (props.imageLeft ? 'row' : 'row-reverse')};
    justify-content: space-around;
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.space[2]}px;
  text-align: center;

  ${media(768)} {
    text-align: left;
    width: 60%;
  }
`

const Title = styled.h2`
  font-weight: 400;
  font-size: 30px;
  padding: ${theme.space[2]}px;
  color: ${theme.colors.font.black};
`

const Subtitle = styled.p`
  font-weight: 400;
  font-size: ${theme.font.xl};
  padding: ${theme.space[2]}px;
  color: ${theme.colors.font.gray};
`

const Image = styled.img`
  width: auto;
  object-fit: contain;
  margin: 0 auto;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: center;
`

FeatureDescriptions.description = Description
FeatureDescriptions.text = Text
FeatureDescriptions.title = Title
FeatureDescriptions.subtitle = Subtitle
FeatureDescriptions.image = Image
FeatureDescriptions.imageContainer = ImageContainer
export default FeatureDescriptions
