import React, { useState } from 'react'
import {
  PricingCardPro,
  PricingCardStarter,
  PricingCardFree,
  PricingCardEcommerce,
} from '../../price-card'
import PricingSection from './feature-pricing.styles'
import Radio from '@eig-builder/core-ui/Radio'
import { getCurrencySymbol } from 'helpers/currency-helper'
import Typography from '@eig-builder/core-ui/Typography'

const FeaturePricing = () => {
  const [isMonthly, setIsMonthly] = useState('yearly')

  const handleChange = (e) => {
    setIsMonthly(e.target.value)
  }

  return (
    <PricingSection>
      <PricingSection.contentContainer>
        <PricingSection.title>Does your site need more?</PricingSection.title>
        <PricingSection.subtitle>
          Whatever tools you need, we have the plan for you
        </PricingSection.subtitle>
        <PricingSection.buttonContainer>
          <PricingSection.radioGroup value={isMonthly} onChange={handleChange}>
            <PricingSection.formControlLabel
              isSelected={isMonthly == 'monthly'}
              value={'monthly'}
              control={<Radio />}
              label='Monthly'
            />
            <PricingSection.formControlLabel
              isSelected={isMonthly == 'yearly'}
              value={'yearly'}
              control={<Radio />}
              label='Yearly'
            />
          </PricingSection.radioGroup>
        </PricingSection.buttonContainer>
      </PricingSection.contentContainer>
      <PricingSection.cardContainer>
        <PricingCardFree />
        <PricingCardStarter isMonthly={isMonthly == 'monthly'} />
        <PricingCardPro isMonthly={isMonthly == 'monthly'} />
        <PricingCardEcommerce isMonthly={isMonthly == 'monthly'} />
      </PricingSection.cardContainer>
      <PricingSection.contentContainer>
        <PricingSection.disclaimer>
          <Typography gutterBottom>
            *For customers in regions in which VAT (Value Added Tax) or GST
            (Goods and Services Tax) is applicable, taxes are not included in
            our advertised price and will be charged separately and itemized on
            invoices and billing information.{' '}
            <a
              href={process.env.GATSBY_EU_VAT}
              targe='_blank'
              rel='noopener norefferer'
            >
              Learn more
            </a>
          </Typography>
          <Typography>
            **Qualified plans include a free domain registration for one year.
            If you wish to cancel, there is a non-refundable{' '}
            {getCurrencySymbol().domainPrice} domain fee.
          </Typography>
        </PricingSection.disclaimer>
      </PricingSection.contentContainer>
    </PricingSection>
  )
}

export default FeaturePricing
