import React from 'react'
import FeatureDescriptionsLayout from './feature-descriptions.styles'
import { DESCRIPTION_CONTENTS } from './feature-descriptions.constants'

const FeatureDescriptions = () => (
  <FeatureDescriptionsLayout>
    {DESCRIPTION_CONTENTS.map((content) => (
      <Description
        key={content.id}
        imageLeft={content.id % 2 === 0}
        title={content.title}
        subtitle={content.subtitle}
        image={content.image}
      />
    ))}
  </FeatureDescriptionsLayout>
)

const Description = (props) => (
  <FeatureDescriptionsLayout.description imageLeft={props.imageLeft}>
    <FeatureDescriptionsLayout.imageContainer>
      <FeatureDescriptionsLayout.image src={props.image} />
    </FeatureDescriptionsLayout.imageContainer>
    <FeatureDescriptionsLayout.text>
      <FeatureDescriptionsLayout.title>
        {props.title}
      </FeatureDescriptionsLayout.title>
      <FeatureDescriptionsLayout.subtitle>
        {props.subtitle}
      </FeatureDescriptionsLayout.subtitle>
    </FeatureDescriptionsLayout.text>
  </FeatureDescriptionsLayout.description>
)

export default FeatureDescriptions
