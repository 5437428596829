import React from 'react'
import Layout from '../components/layout'
import FeatureList from '../components/feature/feature-list'
import FeatureDescriptions from '../components/feature/feature-descriptions'
import FeatureHero from '../components/feature/feature-hero/'
import FeaturePricing from '../components/feature/feature-pricing'
import CTA from '../components/cta'
import SEO from '../components/seo'

const Features = () => {
  return (
    <Layout>
      <SEO title='Features' pageType='features' flowType='none' />
      <FeatureHero />
      <FeatureDescriptions />
      <FeatureList />
      <FeaturePricing />
      <CTA />
    </Layout>
  )
}

export default Features
